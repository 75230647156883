import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
import { FaUser } from 'react-icons/fa';
import { FaFileInvoice } from 'react-icons/fa';
import { FaBuilding } from 'react-icons/fa';
import { FaQuoteLeft } from 'react-icons/fa';
import { FaCalendarAlt } from 'react-icons/fa';
import './sidebar.css';

function SiteSideBar({onLogout}) {

  //const { collapseSidebar } = useProSidebar();

  return (
    <div id="sidebar" style={{ display: 'flex', height: '100%', backgroundColor: 'black', opacity:1  }}>
        <Sidebar >

        <Link to="/home"> <img src="../main-image.png" alt="Logo" width="250" /></Link>

          <Menu>         
          <Link to="/home"> <MenuItem active={window.location.pathname === "/home"}><FaUser style={{color: '#2596be'}}/> Dashboard</MenuItem></Link>
          <Link to="/schedule"><MenuItem active={window.location.pathname === "/schedule"}><FaCalendarAlt style={{color: '#2596be'}}/> Schedule</MenuItem></Link>
          <Link to="/customers"> <MenuItem active={window.location.pathname === "/customers"}><FaUser style={{color: '#2596be'}}/> Customers</MenuItem></Link>
         
          <SubMenu label="Jobs">
            <Link to="/jobs/daily"> <MenuItem active={window.location.pathname === "/jobs/daily"}><FaBuilding style={{color: '#2596be'}}/> Daily Jobs</MenuItem></Link>            
            <Link to="/jobs/future"> <MenuItem active={window.location.pathname === "/jobs/future"}><FaBuilding style={{color: '#2596be'}}/> Future Jobs</MenuItem></Link>
            <Link to="/jobs/past"> <MenuItem active={window.location.pathname === "/jobs/past"}><FaBuilding style={{color: '#2596be'}}/> Past Jobs</MenuItem></Link>
          </SubMenu>

          <Link to="/quotes"> <MenuItem active={window.location.pathname === "/quotes"}><FaQuoteLeft style={{color: '#2596be'}}/> Quotes</MenuItem>     </Link>       
          <Link to="/invoices"> <MenuItem active={window.location.pathname === "/invoices"} label="invoices"><FaFileInvoice style={{color: '#2596be'}}/> Invoices</MenuItem></Link>
          <Link onClick={onLogout}> 
            <MenuItem label="invoices"><FaFileInvoice style={{color: '#2596be'}}/> Logout</MenuItem></Link>
            
          </Menu>
        </Sidebar>
        <main>
        {/* <Button variant="contained" onClick={() => collapseSidebar()}>Collapse</Button> */}
        </main>
    </div>
  );
}

export default SiteSideBar
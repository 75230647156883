import React, { useState } from 'react';
import Button from '@mui/material/Button';

import { BsFillCalendarCheckFill } from 'react-icons/bs';
import "react-datepicker/dist/react-datepicker.css";
import JobAPI from '../../apis/jobs';
import { useNavigate } from "react-router-dom";
import DisplayJobOverview from '../../components/jobs/displayJobOverview';

function DailyJobs() {
    // new or edit state
const [dailyJobs, setDailyJobs] = React.useState([]);

const navigate = useNavigate();

  const getDailyJobs = () => {
    JobAPI.getDailyJobs().then((response) => {
      if(response.status === 200){
        setDailyJobs(response.data);
      }else{
        alert("Getting daily jobs")
      }
    });
  }

  useState(() => {  
    getDailyJobs();
  }, [])


  const newJob = () => {
    navigate('/jobs/new')
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', padding:20}}>
        <div>
          <h2><button onClick={() => navigate(-1)}><img alt="Go back" src="/images/back.png" width={20}/></button> <BsFillCalendarCheckFill/> Daily Jobs </h2> 
        </div><div>
        <Button onClick={newJob} variant="contained">NEW JOB</Button>
        </div>
      </div>
    
      <div style={{borderRadius: 5, padding: 10}}>
        {dailyJobs?.length === 0 ? <p>You better work harder! No jobs booked in today..</p> : ""} 
        {dailyJobs?.map((job) => {
          return (
            <>             
                <DisplayJobOverview job={job}  />   
            </>           
          )})}
    </div>   


    </div>

  );
}

export default DailyJobs;
import React, { useEffect } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { JobAPI } from '../apis/jobs';

function Schedule() {

  
  const [scheduleData, setScheduleData] = React.useState([]);

  useEffect(() => {
    JobAPI.getAllJobs().then((response) => {
      setScheduleData(response.data);
      console.log(response.data)
    }).catch((err) => console.log(err));
  }, [])

  const handleDateClick = (arg) => { // bind with an arrow function
    alert(arg.dateStr);
  };

  return (
    <div style={{padding:20}}>
           <FullCalendar
        plugins={[ dayGridPlugin ]} dateClick={(e) => handleDateClick(e)}
        initialView="dayGridMonth"  weekends={false}
        events={scheduleData}
      />
    </div>    
  );
}

export default Schedule;
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import "react-datepicker/dist/react-datepicker.css";
import JobAPI from '../../apis/jobs';
import { useNavigate } from "react-router-dom";
import DisplayJobOverview from '../../components/jobs/displayJobOverview';

function FutureJobs() {

  const [futureJobs, setFutureJobs] = React.useState([]);

  const navigate = useNavigate();
 
  const getAllJobs = () => {
    JobAPI.getAllJobs().then((response) => {

      if(response?.status === 200){
        var allJobs = response.data;      
        const today = new Date().getTime();

        const futureJobs = allJobs.filter((job)  => {
          return new Date(job.date).getTime() >= today;
        });

        console.log(futureJobs);
        setFutureJobs(futureJobs);

      }else if(response?.status === 401){
        alert("Error authenticating request");
      }else{
        alert("Error getting jobs");
        console.log(response);
      }
    });
  }

  useState(() => {  
    getAllJobs();
  }, [])

  const newJob = () => {
    navigate('/jobs/new')
  }

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', padding:20}}>
        <div>
          <h2><button onClick={() => navigate(-1)}><img alt="Go back" src="/images/back.png" width={20}/></button> <BsFillCalendarCheckFill/> Future Jobs </h2> 
        </div>
        <div>
        <Button onClick={newJob} variant="contained">NEW JOB</Button>
        </div>
      </div>
    
      <br></br>

      <div style={{borderRadius: 5, padding: 10}}>
          {futureJobs?.length === 0 ? <p>No future jobs scheduled</p> : ""} 
          {futureJobs?.map((job) => {
          return (
            <>             
                <DisplayJobOverview job={job} />  
            </>           
          )})}
    </div>
    </>

  );
}

export default FutureJobs;
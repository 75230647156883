import axios from "axios"

export const api = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_API_BASE_URL,
})


api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('stx');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(response => response, error => {
  if(error.response.status === 401) {
    alert("unauthorised");
    return api.request({
      url: `/user/refresh`,
      method: "POST",
      data: { 
              accessToken: localStorage.getItem('stx'),
              refreshToken: localStorage.getItem('stx-r'),
      }}).then(response => {
        console.log("responce from user refresh request");
        console.log(response);
        if(response.status === 200){
        
          localStorage.setItem('stx', response.data.accessToken);
          localStorage.setItem('stx-r', response.data.refreshToken);
          var originalRequest = error.config
       
   
              originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken
             return axios(originalRequest);
           
        }else{
          setTimeout(() => {
            window.location.href = '/login';
          }, 100);
        }
      });
  }
  
  return error;
});
export default api;
import React, { useState } from 'react'
import CustomerAPI from "../apis/customer";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function Register({onLogin } ) {
  let navigate = useNavigate();
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // const validationSchema = Yup.object().shape({
  //   username: Yup.string().required('Username is required'),
  //   password: Yup.string().required('Password is required'),
  // });

  const handleSubmit = async (event) => {

    if(event){
      event.preventDefault();
    };


    if(password.toLowerCase() !== confirmPassword.toLowerCase()){
      setErrorMessage("Your passwords do NOT match.");
      return;
    }

    if(!username){
      setErrorMessage("Please specify your username");
      return;
    }
    if(!password){
      setErrorMessage("Please specify your password");
      return;
    }
    try {

      CustomerAPI.registerUser(emailAddress,username, password, ).then((response) => {
        if(response.status === 200){
          localStorage.setItem('stx', response.data);
          setErrorMessage("");
          onLogin(true);
          navigate("/dashboard");
        }else{
          setErrorMessage("Problem automatically logging you in. Please try logging in again shortly.")
        }
        
      }).catch((err) => 
        setErrorMessage("Incorrect login details")
      );

    }catch (error) {
        // Handle validation error
    }
    
  };

  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", backgroundColor: '#2596be'}}>

      <form style={{width:300, display: "block", padding:10, backgroundColor: "white", borderRadius: 10, textAlign:"center"}} onSubmit={handleSubmit}>
      <h2>Registration</h2><div style={{}} >
        <TextField value={username} onChange={(e) =>  setUsername(e.target.value)}   id="username" label="Username" variant="filled" /> <br/><br/>
        <TextField value={password} type='password' onChange={(e) =>  setPassword(e.target.value)}   id="password" label="Password" variant="filled" /> <br/><br/>
        <TextField value={confirmPassword} type='password' onChange={(e) =>  setConfirmPassword(e.target.value)}   id="confirmPassword" label="Confirm password" variant="filled" /> <br/><br/>
        <TextField value={emailAddress} onChange={(e) =>  setEmailAddress(e.target.value)}   id="emailaddress" label="Email address" variant="filled" /> <br/><br/>
        { errorMessage && 
          <div style={{color: "red", marginBottom:10}}><i>{errorMessage}</i></div>
        }
        

        <Button type='submit' variant="contained">Register</Button>
        </div>
       
      
    </form>
    </div>
  
  );
}

export default Register;
import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import JobAPI from '../../apis/jobs';
import { useNavigate } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CustomerAPI from "../../apis/customer";
import { InputAdornment, InputLabel } from '@mui/material';




function NewJob() {
  const navigate = useNavigate();

  // new or edit state
  const [title, setTitle] = React.useState('');  
  const [description, setDescription] = React.useState('');
  const [date, setDate] = React.useState('');
  const [customerId, setCustomerId] = React.useState(0);
  const [totalCost, setTotalCost] = React.useState('0');
  const [customerList, setCustomerList] = React.useState([{ id: 0, firstName: 'Please select', lastName: ''}]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);;
  }

  React.useState(() => {
    CustomerAPI.getAllCustomers().then((response) => {
      if(response?.status === 200){
        setCustomerList(response.data);
      }else{
        alert("Problem loading customer data")
      }
    });
  }, [])

  const createJob = () => {
    JobAPI.createJob(title, description, date, customerId, totalCost).then((response) => {
      if(response?.status === 200){
        navigate("/jobs");
      }else{
        alert("Problem saving job")
      }
    });
  }

  const setSelectedCustomerId = (customerId) => {
    setCustomerId(customerId);
  }

  return (
    <div style={{padding:20}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h2><button onClick={() => navigate(-1)}><img alt="Go back" src="/images/back.png" width={20}/></button> <BsFillCalendarCheckFill/> Create New Job </h2> 
        </div>
      </div>
    
      <TextField value={title} onChange={(e) => setTitle(e.target.value)} id="title" label="Title" variant="filled" style={{width:500}} /> <br/><br/>
      <TextField multiline={true} value={description} onChange={(e) => setDescription(e.target.value)} id="description" label="Job Description" variant="filled"  style={{width:500}} /> <br/><br/>
      <TextField value={totalCost} onChange={(e) => setTotalCost(e.target.value)} id="cost" label="Customer charge amount" variant="filled" InputProps={{
  startAdornment: <InputAdornment position="start">£</InputAdornment>,
}} /> <br/><br/>
      <DatePicker placeholderText='Date/Time' value={date} showTimeSelect selected={date} onChange={(date) => setDate(date)} variant="filled" /> <br/><br/>
      <InputLabel id="demo-simple-select-label">Customer</InputLabel>
      <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerId}  
    label="Customer"
    onChange={(e) => setSelectedCustomerId(e.target.value)}
  >
     <MenuItem key={0} value={0}>Please select</MenuItem> 
    {customerList?.map((customer) => {
      return (    
          <MenuItem key={customer.id} value={parseInt(customer.id)}>{capitalizeFirstLetter(customer.firstName)} {capitalizeFirstLetter(customer.lastName)}</MenuItem>        
      )})}
  </Select>


      <br/><br/>
      <Button  variant="contained"  onClick={() => createJob()} >Save</Button>
    </div>

  );
}

export default NewJob;
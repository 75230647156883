import { CgProfile } from 'react-icons/cg';
import Button from '@mui/material/Button';


function Header({onLogout}) {
  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', height: 50, backgroundColor: '#2596be', padding:10}}>
      
      <div>
        <Button id='profileButton' style={{margin: 10, color:'white'}}  variant="outlined"><CgProfile fontSize="20"></CgProfile><div style={{margin: 2}} > Profile</div></Button>
        <Button style={{margin: 10,color:'white'}} onClick={onLogout}  variant="outlined"><CgProfile fontSize="20"></CgProfile><div style={{margin: 2}} > Logout</div></Button>
      </div> 

    </div>
       
  );
}
export default Header;

import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NewCustomer from './new-customer';
import Modal from 'react-modal';
import CustomerAPI from '../apis/customer';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit, FaArrowAltCircleLeft, FaPlusCircle, FaSearch} from 'react-icons/fa';
import useCheckMobileScreen from '../components/layout/useCheckMobileScreen.js';
import { useNavigate } from "react-router-dom";

function Customers() {
  const navigate = useNavigate();
  var isMobile = useCheckMobileScreen();
  const [customerData, setCustomerData] = React.useState([]);
  const [searchData, setSearchData] = React.useState([]);
  const [searchCustomerId, setSearchCustomerId] = React.useState("");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  // function openModal() {
  //   setIsOpen(true);
  // }

  function closeModal() {
    setIsOpen(false);
  }

  const addNameSearch = (text) => {
    setSearchData((prevState) => ({
      ...prevState,
      nameText: text,
    }));
  }

  const addEmailAddressSearch = (text) => {
    setSearchData((prevState) => ({
      ...prevState,
      emailAddress: text,
    }));
  }

  const addPhoneNumberSearch = (text) => {
    setSearchData((prevState) => ({
      ...prevState,
      phoneNumber: text,
    }));
  }

  const getAllCustomers = () => {
    
    CustomerAPI.getAllCustomers().then((response) => {
      setIsDataLoaded(true);
      if(Array.isArray(response.data)){
        setCustomerData(response.data);
      }
   
    }).catch((err) => setIsDataLoaded(true));
  };

  React.useEffect(() => {
    getAllCustomers();
  }, []);

  const search = () => {

    // move this to just use search endpoint
    if(searchCustomerId){
      CustomerAPI.getById(searchCustomerId).then((response) => {
        setCustomerData([response.data]);
        
      }).catch((err) => console.log(err));

    }else{
      CustomerAPI.searchCustomers(searchData).then((response) => {
        if(Array.isArray(response.data)){
          setCustomerData(response.data);
        }else{
          setCustomerData([]);
        }
      }).catch((err) => console.log(err));

    }
     
    if(searchData?.name === "" && searchData?.phoneNumber === "" && searchCustomerId === ""){
      getAllCustomers();
    }
  };

  const deleteCustomer = (customerId) => {
     
    CustomerAPI.deleteCustomer(customerId).then(() => {
      setCustomerData(customerData.filter(x => x.id !== parseInt(customerId)))
    });
  };
  
  const editCustomer = (customerId) => {
    navigate("/customers/edit/" + customerId);   
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  
   return (
    <div style={{padding:20}} >
      
        <div style={{display: "flex", alignItems: "center"}}>
          <FaArrowAltCircleLeft  style={{color:"blue", marginRight: 10}} onClick={() => navigate(-1)}  />
          <h3> Customers</h3> 
          <Button style={{marginLeft: "auto"}} onClick={() => navigate("/customers/new")}><FaPlusCircle style={{height:50, width:25}} /> </Button>
        </div>
        
        { !isMobile && 
        <><TextField style={{marginRight:20, width: 300}} value={searchCustomerId} onChange={(e) => setSearchCustomerId(e.target.value)}  label="Customer ID" variant="filled" /> 
        <TextField style={{marginRight:20, width: 300}} value={searchData?.name} onChange={(e) => addNameSearch(e.target.value)}    label="Name" variant="filled" /> 
        </>          
        }
      
        <TextField style={{marginRight:20, width: 300}} value={searchData?.phoneNumber} onChange={(e) => addPhoneNumberSearch(e.target.value)}    label="Phone number" variant="filled" />
        { !isMobile && 
         <TextField style={{marginRight:20, width: 300}} value={searchData?.emailaddress} onChange={(e) => addEmailAddressSearch(e.target.value)}    label="Email Address" variant="filled" />  
        }

<Button onClick={search} style={{marginLeft: "auto"}}><FaSearch style={{height:50, width:25}} /><br /><br /></Button>


        { !isDataLoaded &&
        <div id="loading">Loading customer data.</div>
        }
        {customerData?.length === 0  && isDataLoaded ? <p>No customers</p> : ""} 
        {customerData?.map((customer) => {
          return (
            <React.Fragment key={customer.id} >                        
                <div style={{display:"flex", alignItems: "center"}}>
                  <b>  {customer.firstName} {customer.lastName}</b><br/><br/> 
                  <div id="buttons" style={{marginLeft: "auto", order: 2}}>
                    <Button  onClick={() => editCustomer(customer.id)}  style={{marginRight:10}} id="outlined"  variant="contained" ><FaEdit size={10}/> 
                    { !isMobile && 
                      <>&nbsp;Edit</>
                    }</Button>
                    <Button onClick={() => deleteCustomer(customer.id)} style={{backgroundColor:"red"}} id="outlined"  variant="contained" ><AiFillDelete size={10}/> 
                    { !isMobile && 
                      <>&nbsp;Delete</>
                    }</Button>
                    </div>
                  </div>
      
                 <hr style={{border:"none", height: 1, color:"lightgrey", backgroundColor:"lightgrey"}}/> 
          
                 </React.Fragment>          
        )})}
        <div>
     
    </div>
    <Modal
       isOpen={modalIsOpen}
       onRequestClose={closeModal}
       style={customStyles}
       contentLabel="Example Modal"
      >
        <NewCustomer />
       
       
     
      </Modal>
    </div>    
  );
}

export default Customers;
import React, { useState } from 'react'
import CustomerAPI from "../apis/customer";
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function Login({onLogin } ) {
  let navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // const validationSchema = Yup.object().shape({
  //   username: Yup.string().required('Username is required'),
  //   password: Yup.string().required('Password is required'),
  // });

  const handleSubmit = async (event) => {

    if(event){
      event.preventDefault();
    };

    try {
      setErrorMessage("Logging you in..");
      CustomerAPI.loginUser(username, password).then((response) => {
        if(response.status === 200){
          
          localStorage.setItem('stx', response.data.accessToken);
          localStorage.setItem('stx-r', response.data.refreshToken);
    
          setErrorMessage("");
          onLogin(true);
          navigate("/dashboard");
        }else{
          setErrorMessage("Incorrect login details")
        }
        console.log("set stx key");
        console.log(response);
        
      }).catch((err) => 
        setErrorMessage("Incorrect login details")
      );

    }catch (error) {
        // Handle validation error
    }
    
  };

  return (
    <>
  
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
      <form style={{width:300, padding:10, display:"block", backgroundColor: "white", borderRadius: 10, textAlign:"center"}} onSubmit={handleSubmit}>
        <div>

          <h2>CR Property Renovations</h2>
          <TextField value={username} onChange={(e) =>  setUsername(e.target.value)}   id="username" label="Username" variant="filled" /> <br/><br/>
          <TextField value={password} type='password' onChange={(e) =>  setPassword(e.target.value)}   id="password" label="Password" variant="filled" /> <br/><br/>

          { errorMessage && 
            <div style={{color: "red", marginBottom:10}}><i>{errorMessage}</i></div>
          }

          <Link style={{color:"black", fontSize: 14, textDecoration: "none"}} to="/register">New Customer?<br /> <i>Sign up now and begin your jouney</i></Link><br></br>
          <Button type='submit' variant="contained">Login</Button>

        </div>
      </form>
    </div>



    </>


  
  );
}

export default Login;
import Button from '@mui/material/Button';
import { sendSMSMessage } from '../../services/jobService'
import Modal from 'react-modal';


function DisplayJobOverviewSMSModal({customerId, modalIsOpen, closeModal}) {
  
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: 350
    },
  };
  
  return (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Job">
        {
            <div style={{textAlign: 'center'}} >
                <h2>Send SMS to customer</h2>
                <Button onClick={() => {sendSMSMessage(customerId, 1)}} style={{marginRight:20,marginBottom:10}} id="outlined"  color='success'  variant="contained" > Send On The Way SMS</Button>< br />
                <Button onClick={() => {sendSMSMessage(customerId, 2)}} style={{marginRight:20,marginBottom:10}} id="outlined"  color='success'  variant="contained" > Send Sorry Im Late SMS</Button>< br />
                <Button onClick={() => {sendSMSMessage(customerId, 3)}} style={{marginRight:20,marginBottom:10}} id="outlined"  color='success'  variant="contained" > Send TrustPilot Review SMS</Button>< br />
                <Button onClick={() => {sendSMSMessage(customerId, 3)}} style={{marginRight:20,marginBottom:10}} id="outlined"  color='success'  variant="contained" > Send TrustPilot Review SMS</Button>< br />
            </div>
        }
        </Modal>   
  );
}
export default DisplayJobOverviewSMSModal;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";

import { FaArrowAltCircleLeft} from 'react-icons/fa';
import CustomerAPI from '../apis/customer';

function NewCustomer() {
  const navigate = useNavigate();
    const [ user, setUser ] = useState([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
          setUser(codeResponse)
          console.log(codeResponse);
        },
        onError: (error) => console.log('Login Failed:', error),
        scope: 'https://www.googleapis.com/auth/contacts'
    });

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");  
  const [addressLineOne, setAddressLineOne] = React.useState("");
  const [addressLineTwo, setAddressLineTwo] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");  
  const [emailAddress, setEmailAddress] = React.useState("");
  const [syncToGoogleContacts, setSyncToGoogleContacts] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');


  const handleSyncGoogleChange = () => {
    setSyncToGoogleContacts(!syncToGoogleContacts);
  };

  const saveNewCustomer = (firstName,lastName,postalCode,addressLineOne,addressLineTwo,phoneNumber, emailAddress, googleContactsJWTToken) => {   

    alert("saving customer started")
    setErrorMessage('');
    //validate data 
    var csc = [ firstName]
    csc.forEach((stateKey)=>{
      if (!stateKey) {
        setErrorMessage('The first name should not be blank');
      }
    });

    if(errorMessage === ''){
      alert("savcing customer api request")
      CustomerAPI.saveNewCustomer(firstName, lastName, postalCode,addressLineOne,addressLineTwo,phoneNumber, emailAddress, googleContactsJWTToken).then((response) => {
        navigate("/customers");
      }).catch((err) => 
      {
        alert(err);
      });
    }
   
  };

  return (
    <div style={{padding:20}}>
         <div style={{display: "flex", alignItems: "center"}}>
          <FaArrowAltCircleLeft  style={{color:"blue", marginRight: 10}} onClick={() => navigate(-1)}  />
          <h3>New Customer</h3> 
        </div>
      <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} id="firstName" label="First Name" variant="filled" /> <br/><br/>
      <TextField value={lastName} onChange={(e) => setLastName(e.target.value)}   id="lastName" label="Last Name" variant="filled" /> <br/><br/>
      <TextField value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}   id="PhoneNumber" label="Phone Number" variant="filled" /> <br/><br/>
      <TextField value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)}   id="EmailAddress" label="Email Address" variant="filled" /> <br/><br/>
      <TextField value={postalCode} onChange={(e) => setPostalCode(e.target.value)}   id="Postcode" label="Postcode" variant="filled" /> <br/><br/>
      <TextField value={addressLineOne} onChange={(e) => setAddressLineOne(e.target.value)}   id="AddressLineOne" label="Address Line One" variant="filled" /> <br/><br/>
      <TextField value={addressLineTwo} onChange={(e) => setAddressLineTwo(e.target.value)}   id="AddressLineTwo" label="Address Line Two" variant="filled" /> <br/><br/>

      <div>
        <input style={{minWidth:0, width: 15}} type="checkbox" name="sync-google-contacts" value="test" checked={syncToGoogleContacts} onChange={handleSyncGoogleChange}/>  <label for="sync-google-contacts">Sync google contact</label>
      </div>
  
     
      {syncToGoogleContacts && user.access_token == null &&
          <button onClick={() => login()}>Sign in with Google to sync 🚀 </button>
      }
      
      {errorMessage !== ''  &&
         <div style={{backgroundColor:'red', padding:10, color: 'white' ,marginTop:20}}>{errorMessage}</div>
      }  
       
      <br/>
      <Button  variant="contained"  onClick={() => saveNewCustomer(firstName,lastName,postalCode,addressLineOne,addressLineTwo,phoneNumber, emailAddress, user.access_token)} >Save</Button>
    </div>    
  );
}
export default NewCustomer;

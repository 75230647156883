import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useParams } from "react-router-dom";
import React from 'react'
import JobAPI from '../apis/jobs';
import CheckoutForm from '../components/payment/CheckoutForm';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Payment() {

  const { paymentId } = useParams()
  const [clientSecret, setClientSecret] = React.useState('');  
  
  const [job, setJob] = React.useState({
      id: 0,
      customerId: 0,
      title: '',
      description: '',
      date: '',
      status: 0,
      createdByUserId: 0,
      totalCost: 0.00
  });

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  React.useEffect(() => {
    JobAPI.createPaymentIntent(encodeURIComponent(paymentId)).then((response) => {
      if(response.status === 200){
        setClientSecret(response.data);
      }else{
        alert("Error obtaining payment intent. Please get in touch.")
      }
    })
  },[paymentId]);

  React.useEffect(() => {
    JobAPI.getJobPublic(encodeURIComponent(paymentId)).then((response) => {
      if(response.status === 200){
        setJob(response.data)
      }else{
        alert("Error obtaining job data. Please get in touch.")
      }
    })
  },[paymentId]);

  return (
    <>
  

      { clientSecret !== '' && 
         <div style={{backgroundColor: 'white', borderRadius: 5, width: '100%', padding: 20, textAlign: 'center'}}>

      <img src="https://crpropertyrenovations.co.uk/images/main-image.png" alt="Logo" style={{width: '70%', maxWidth: 500}} />
      
        <div id="jobDetails" style={{backgroundColor: 'antiquewhite', borderRadius: 5, padding: 20, borderColor: 'lightgray', marginBottom: 20}}>
          <h2>Job Details</h2>
          <p><b>Title</b> <br />{job.title}</p>
          <p><b>Description</b> <br />{job.description}</p>
          <p><b>Price</b><br /> £{job.totalCost}</p>
        </div>

        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm totalCost={job.totalCost}/>
        </Elements>
         </div> 
        }
    </>
  
    
  )
};
import { api } from "./jobbase"
import { defineCancelApiObject } from "./axiosUtils"

export const JobAPI = {
    getAllJobs: function (cancel = false) {
        return api.request({
            url: `/job/all`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    }, 
    getJob: function (jobId, cancel = false) {
        return api.request({
            url: `/job?jobId=` + jobId,
            method: "GET",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    }, 
    getJobPublic: function (id, cancel = false) {
        return api.request({
            url: `/job/GetJobByEncrpytedId?encryptedId=` + id,
            method: "GET",
            validateStatus: null,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    }, 
    getDailyJobs: function (cancel = false) {
        return api.request({
            url: `/job/GetDailyJobs`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    createJob: function (title, description, date, customerId, totalCost, cancel = false) {
        return api.request({
            url: `/job`,
            method: "POST",
            data: { 
                job: {
                    title: title, 
                    description: description,
                    date: date,
                    customerId: customerId,
                    totalCost: totalCost
                }            
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    sendSMSMessage: function (phoneNumber, smsType, cancel = false) {
        return api.request({
            url: `/job/SMSMessage?phoneNumber=` + phoneNumber + '&smsType=' + smsType,
            method: "POST",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    createOnlinePaymentSMSRequest: function (phoneNumber, jobId, cancel = false) {
        return api.request({
            url: `/job/CreateOnlinePaymentSMSRequest`,
            method: "POST",
            data: { 
                    phoneNumber: phoneNumber, 
                    jobId: jobId
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    createPaymentIntent: function (encrpytedJobId, cancel = false) {
        return api.request({
            url: `/PaymentIntent?jobId=` + encrpytedJobId,
            method: "POST",
            validateStatus: null,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    updateJob: function (title, description, date, customerId, jobId, totalCost, cancel = false) {
        return api.request({
            url: `/job`,
            method: "PUT",
            data: { 
                updatedJob: {
                    title: title, 
                    description: description,
                    date: date,
                    customerId: customerId,
                    id: jobId,
                    totalCost: totalCost
                }            
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(JobAPI)

export default JobAPI
import { Link } from "react-router-dom";

function HomeLink({ colour, url, title, func, img }) {

var imageSrc= "images/"+img+".png";
console.log(imageSrc);

  return (
    <Link to={url} onClick={func}>
      <div className="homeSelector" style={{backgroundColor: colour, textAlign: "center", borderRadius: 8, border: 12, borderColor: "black", marginRight:20}}>
        { img != null && 
           <img src={imageSrc} alt={img + ' home link'} style={{maxHeight: 100}} />
        }
        <h3>{title}</h3>
      </div>
    </Link>
    
  );
}
export default HomeLink;

import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Customers from './pages/customers';
import Layout from './components/layout/layout';
import PastJobs from './pages/jobs/pastjobs';
import Home from './pages/home';
import Schedule from './pages/schedule';
import Invoices from './pages/invoices';
import Login from './pages/login';
import './styles/style.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Quotes from './pages/quotes';
import NewQuote from './pages/newquote';
import NewCustomer from './pages/new-customer';
import DailyJobs from './pages/jobs/dailyjobs';
import EditCustomer from './pages/edit-customer';
import React, { useState, useReducer } from 'react';
import Register from './pages/register';
import NewJob from './pages/jobs/new-job';
import EditJob from './pages/jobs/edit-job';
import PaymentSuccess from './pages/payment-success';
import Payment from './pages/payment';
import FutureJobs from './pages/jobs/futurejobs';

function App() {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [isLoggedIn, setIsLoggedIn] = useState(
    () => localStorage.getItem('stx') !== null
  );
  
  const logIn = () => {
    setIsLoggedIn('true');
  };

  // pass this callback to components you want to allow logging out
  // it will update the local state and then get persisted
   const onLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('stx');
    forceUpdate();
   };


    return (
      <>
        <ProSidebarProvider> 
            <BrowserRouter>
              <Routes> 
              <Route path="payment/:paymentId" element={<Payment />} />
              <Route path="payment/success" element={<PaymentSuccess />} />     
              <Route path="login" element={<Login onLogin={logIn} />} /> 
              <Route path="register" element={<Register onLogin={logIn} />} />
                <Route path="/" element={<Layout isLoggedIn={isLoggedIn} onLogout={onLogout} /> } exact>
                  <Route index element={isLoggedIn ? <Home /> :  <Navigate to='/login'/>} />
                
                  <Route path="schedule" element={isLoggedIn ? <Schedule />  : <Navigate to='/login'/>} />
                  <Route path="customers/edit/:customerId" element={isLoggedIn ? <EditCustomer />  : <Navigate to='/login'/>} />
                  <Route path="customers/new" element={isLoggedIn ? <NewCustomer />  : <Navigate to='/login'/>} />
                  <Route path="customers" element={isLoggedIn ? <Customers />  : <Navigate to='/login'/>} />
                  <Route path="jobs/past" element={isLoggedIn ? <PastJobs />  : <Navigate to='/login'/>} />
                  <Route path="jobs/new" element={isLoggedIn ? <NewJob />  : <Navigate to='/login'/>} />
                  <Route path="jobs/future" element={isLoggedIn ? <FutureJobs />  : <Navigate to='/login'/>} />
                  <Route path="jobs/daily" element={isLoggedIn ? <DailyJobs />  : <Navigate to='/login'/>} />
                  <Route path="jobs/edit/:jobId" element={isLoggedIn ? <EditJob />  : <Navigate to='/login'/>} />
                  <Route path="quotes" element={isLoggedIn ? <Quotes />  : <Navigate to='/login'/>} />
                  <Route path="quote/new" element={isLoggedIn ? <NewQuote />  : <Navigate to='/login'/>} />
                  <Route path="invoices" element={isLoggedIn ? <Invoices />  : <Navigate to='/login'/>} />
                  <Route path="*" element={isLoggedIn ? <Home /> :  <Navigate to='/login'/>}  />
                </Route>
              </Routes>      
            </BrowserRouter>
        </ProSidebarProvider>      
      </>
    );
  }
  


export default App;

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from "react-router-dom";
import JobAPI from '../../apis/jobs';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CustomerAPI from "../../apis/customer";

function EditJob() {
  const navigate = useNavigate();
  const { jobId } = useParams();

  const [customerList, setCustomerList] = React.useState([{ id: 0, firstName: 'Please select', lastName: ''}]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);;
  }

  React.useState(() => {
    CustomerAPI.getAllCustomers().then((response) => {
      setCustomerList(response.data);
    });
  }, [])

  const [job, setJob] = useState({
    id: 0,
    customerId: 0,
    title: '',
    description: '',
    date: '2022-02-01',
    status: 0,
    createdByUserId: 0,
    totalCost: 0.00
});


  const [customerId, setCustomerId] = useState('');

  useEffect(() => {
    JobAPI.getJob(jobId).then((response) => {
      console.log(response);
      setJob(response.data);
      setCustomerId(response.data.customerId);
    })
  },[jobId]);

const updateJob = () => {
  JobAPI.updateJob(job.title, job.description, job.date, customerId, job.id, job.totalCost).then((response) => {
    if(response.status === 200){
      navigate('/jobs')
    }
  })
}

  function handleJobChange(e) {
    const key = e.target.name;
    const value = e.target.value;

    setJob(prev => ({
      ...prev,
      [key]: value
    }));
  }

  function handleDateChange(date) {
 
    setJob(prev => ({
      ...prev,
      "date": date
    }));
  }
  function handleCustomerIdChange(customerId) {
    setCustomerId(customerId);
  }
  return (
    <div style={{padding:20}}>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <div>
        <h2><button onClick={() => navigate(-1)}><img alt="Go back" src="/images/back.png" width={20}/></button> <BsFillCalendarCheckFill/> Edit Job </h2> 
      </div>
    </div>
  
    <TextField name="title" value={job.title} onChange={(e) => handleJobChange(e)} id="outlined-basic" label="Title" variant="filled" /> <br/><br/>
    <TextField name="description" multiline={true} value={job.description} onChange={(e) => handleJobChange(e)} id="outlined-basic" label="Job Description" variant="filled" /> <br/><br/>
     <DatePicker dateFormat="MM-dd-yyyy"
  selected={new Date(job.date)}
 name="date" label="Date/Time" showTimeSelect onChange={(date) => handleDateChange(new Date(date))} /> <br/><br/> 

    <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"   
    value={customerId}  
    label="Customer"
    onChange={(e) => handleCustomerIdChange(e.target.value)}
  >
    {customerList?.map((customer) => {
    return (    
         <MenuItem key={customer.id} value={customer.id.toString()}>{capitalizeFirstLetter(customer.firstName)} {capitalizeFirstLetter(customer.lastName)}</MenuItem>        
    )})}
  </Select>
      <br/><br/>
    <TextField name="totalCost" value={job.totalCost} onChange={(e) => handleJobChange(e)} id="outlined-basic" label="Total Cost" variant="filled" /> <br/><br/>
    <br/><br/>
    <Button  variant="contained"  onClick={() => updateJob()}>Update</Button>
  </div>
  );
}
export default EditJob;

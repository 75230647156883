import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import "react-datepicker/dist/react-datepicker.css";
import JobAPI from '../../apis/jobs';
import { useNavigate } from "react-router-dom";
import DisplayJobOverview from '../../components/jobs/displayJobOverview';

function PastJobs() {

  const [pastJobs, setPastJobs] = React.useState([]);

  const navigate = useNavigate();

  const newJob = () => {
    navigate('/jobs/new')
  }
  
  const getAllJobs = () => {
    JobAPI.getAllJobs().then((response) => {

      if(response?.status === 200){
        var allJobs = response.data;      
        const now = new Date().getTime();

        const pastJobs = allJobs.filter((job)  => {
          return new Date(job.date).getTime() <= now;
        });

        console.log(pastJobs);
        
        setPastJobs(pastJobs);
      }else if(response?.status === 401){
        alert("Error authenticating request. Please login again.")
      }
      else{
        alert("Error, please try again.")
        console.log(response);
      }
    });
  }

  useState(() => {  
    getAllJobs();
  }, [])

  
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', padding:20}}>
        <div>
          <h2><button onClick={() => navigate(-1)}><img alt="Go back" src="/images/back.png" width={20}/></button> <BsFillCalendarCheckFill/> Past Jobs </h2> 
        </div>
        <div>
        <Button onClick={newJob} variant="contained">NEW JOB</Button>
        </div>
      </div>
    
      <div style={{borderRadius: 5, padding: 10}}>
        {pastJobs?.length === 0 ? <p>No past jobs</p> : ""} 
        {pastJobs?.map((job) => {
          return (
            <>             
                 <DisplayJobOverview job={job} />  
            </>           
          )})}
    </div>   
               
    
    </>

  );
}

export default PastJobs;
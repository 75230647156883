import React from 'react'

function PaymentSuccess({onLogin } ) {
  
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", backgroundColor: '#2596be'}}>
      <h1>Thank you for your payment</h1>
    </div>
  
  );
}

export default PaymentSuccess;
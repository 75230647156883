import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Quotes() {
  const navigate = useNavigate();
  return (
    <div style={{padding:20}}>
      <h2> <button onClick={() => navigate(-1)}><img alt="Go back" src="/images/back.png" width={20}/></button> Quotes</h2>
        <Link to="/quote/new">Create Quote</Link>
    </div>    
  );
}

export default Quotes;
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from "react-router-dom";

import { FaArrowAltCircleLeft} from 'react-icons/fa';
import CustomerAPI from '../apis/customer';

function EditCustomer() {
  const navigate = useNavigate();
  const { customerId } = useParams();
  
  const [customer, setCustomer] = useState({
    id: 0,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: ''
  });
  const [address, setAddress] = React.useState({
    id: 0,
    addressLineOne: '',
    addressLineTwo: '',
    postCode: ''
  });
 
  const editCustomer = () => {   

    CustomerAPI.editCustomer(customer, address).then(() => {
      navigate("/customers");
    }).catch((err) => console.log(err));

    
  };

  function handleCustomerChange(e) {
    const key = e.target.name;
    const value = e.target.value;

    setCustomer(prev => ({
      ...prev,
      [key]: value
    }));
  }

  
  function handleAddressChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    setAddress(prev => ({
      ...prev,
      [key]: value
    }));
  }

  useEffect(() => {
    CustomerAPI.getById(customerId).then((response) => {
      console.log(response);
      setCustomer(response.data);
      setAddress(response.data.address);

    })
  },[customerId]);
  
  function openMaps(){
    const newWindow = window.open('http://maps.google.com/?q=' + address.addressLineOne + ' ' +address.addressLineTwo + ' ' + address.postCode, '_blank', 'noopener,noreferrer');
    if (newWindow){
      newWindow.opener = null
    }
  }

  return (
    <div style={{padding:20}}>
         <div style={{display: "flex", alignItems: "center"}}>
          <FaArrowAltCircleLeft  style={{color:"blue", marginRight: 10}} onClick={() => navigate(-1)}  />
          <h3>Edit Customer</h3> 
        </div>
      <TextField name="firstName" value={customer.firstName} onChange={(e) =>  handleCustomerChange(e)}  id="outlined-basic" label="First Name" variant="filled" /> <br/><br/>
      <TextField name="lastName" value={customer.lastName} onChange={(e) =>  handleCustomerChange(e)}  id="outlined-basic" label="Second Name" variant="filled" /> <br/><br/>
      <TextField name="phoneNumber" value={customer.phoneNumber} onChange={(e) =>  handleCustomerChange(e)}   id="outlined-basic" label="Phone Number" variant="filled" /> <br/><br/>
      <TextField name="emailAddress" value={customer.emailAddress} onChange={(e) =>  handleCustomerChange(e)}   id="outlined-basic" label="Email Address" variant="filled" /> <br/><br/>
      <TextField name="postCode" value={address.postCode} onChange={(e) =>  handleAddressChange(e)} id="outlined-basic" label="Postcode" variant="filled" /> <br/><br/>
      <TextField name="addressLineOne" value={address.addressLineOne} onChange={(e) =>  handleAddressChange(e)}  id="outlined-basic" label="Address Line One" variant="filled" />  { address.addressLineOne && 
      <>
        <button onClick={openMaps} style={{border:0,backgroundColor:'white'}} ><img alt='google maps pin for customer' src='/images/google_maps_icon.webp' style={{width:50}} /></button> 
      </>      
    } <br/><br/>
      <TextField name="addressLineTwo" value={address.addressLineTwo} onChange={(e) =>  handleAddressChange(e)}   id="outlined-basic" label="Address Line Two" variant="filled" /> <br/><br/>            
       
<br/>
  
      <Button  variant="contained"  onClick={editCustomer} >Save</Button>
    </div>    
  );
}
export default EditCustomer;

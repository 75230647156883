import SiteSideBar from './sidebar.js';
import Header from './header.js';
import { Outlet } from "react-router-dom";
import useCheckMobileScreen from './useCheckMobileScreen.js';

function Layout({onLogout, isLoggedIn}) {

  var isMobile = useCheckMobileScreen();

  return (
    <>
        {isLoggedIn && !isMobile &&
            <SiteSideBar/>
        }
        <div style={{backgroundColor: "white", flex: 1}}>
          {isLoggedIn &&
            <Header  onLogout={onLogout}/>
          } 
          <Outlet  />
        </div>
      
    </>    
  );
}

export default Layout;

import HomeLink from "../components/homeLink";

function Home() {
  return (
    <div style={{padding:20}}>

      <h3>Quick Actions</h3>
      <div style={{display: "flex", flexWrap: "wrap", padding:20, borderRadius: 5, border: '1px solid #2596be', justifyContent:'center'}}>
        <HomeLink title="Customers" url="/customers" colour="white" img="customers" />
        <HomeLink title="Daily Jobs" url="/jobs/daily" colour="white" img="jobs"/>
        <HomeLink title="Future Jobs" url="/jobs/future" colour="white" img="jobs"/>
        <HomeLink title="Past Jobs" url="/jobs/past" colour="white" img="jobs"/>
        {/* <HomeLink title="Quotes" url="/quotes" colour="white" img="quotes"/>       
        <HomeLink title="Invoices" url="/invoices" colour="white" img="invoices"/> */}
       </div>
    </div>    
  );
}

export default Home;
import { useNavigate } from "react-router-dom";

function Invoices() {
  const navigate = useNavigate();
  return (
    <div style={{padding:20}}>
        <button onClick={() => navigate(-1)}><img alt="Go back" src="/images/back.png" width={20}/></button>  Invoices
    </div>    
  );
}

export default Invoices;
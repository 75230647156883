import CustomerAPI from '../apis/customer';
import JobAPI from '../apis/jobs';

const openGoogleMaps = (customerId) => {

    CustomerAPI.getById(customerId).then((response) => {
      if(response.status === 200){
        
        console.log("customer data"); 
        console.log(response.data);
        var address = response.data.address;

            if(address.addressLineOne === ""){
                alert("Address not completed")
                return;
            }
        const newWindow = window.open('http://maps.google.com/?q=' + address.addressLineOne + ' ' +address.addressLineTwo + ' ' + address.postCode, '_blank', 'noopener,noreferrer');
        if (newWindow){
          newWindow.opener = null
        }
      }else{
        alert("Cant get customer details")
      }

    });

}

const sendSMSPaymentLink = (customerId, jobId) => {

    let text = "Are you sure you want to send a message to the customer?";
      if (window.confirm(text) === true) {
        CustomerAPI.getById(customerId).then((response) => {
          if(response.status === 200){
            
            var phoneNumber = response.data.phoneNumber;
            
            if(phoneNumber !== null && phoneNumber !== ""){
              JobAPI.createOnlinePaymentSMSRequest(phoneNumber,jobId).then((response) => {
                if(response.status === 200){
                  alert("SMS Payment Link Sent")
                }else{
                  alert("Error sending Payment Link SMS message")
                }
              });
            }else{
              alert("Cant find customers phone number")
            }
            
          }else{
            alert("Error getting customer data")
          }
        });
      }
  };

  const sendSMSMessage = (customerId, smsType) => {

    let text = "Are you sure you want to send a message to the customer?";
      if (window.confirm(text) === true) {
        CustomerAPI.getById(customerId).then((response) => {
          if(response.status === 200){
            
            var phoneNumber = response.data.phoneNumber;
    
            JobAPI.sendSMSMessage(phoneNumber,smsType).then((response) => {
              if(response.status === 200){
                alert("SMS Sent")
              }else{
                alert("Error sending SMS message")
              }
            });
          }else{
            alert("Error getting customer data")
          }
        });
      }
  

   
  };

  export {
    openGoogleMaps,
    sendSMSPaymentLink,
    sendSMSMessage,
  }
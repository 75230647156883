import Button from '@mui/material/Button';
import { openGoogleMaps, sendSMSPaymentLink } from '../../services/jobService'
import DisplayJobOverviewSMSModal from './displayJobOverviewSMSModal';
import React from 'react';
import { useNavigate } from "react-router-dom";

function DisplayJobOverview({job}) {


  const navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const sendSMS = () => {
    openModal(true);
  }
 
  const openModal = (isEditing = false) => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const editJob = (jobId) => {
    navigate('/jobs/edit/' + jobId)
  }

  return (
    <div style={{backgroundColor:'lightblue', padding:20, borderRadius:5, marginTop:10,paddingBottom:5}}>

      <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', height:30}}>
                          
        <p style={{fontSize:20}}><i>{job.title}</i></p>
        
        <b>{new Intl.DateTimeFormat("en-GB", {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
              }).format(new Date(job.date))} 
        </b>
      </div>
      <p style={{margin:0, marginBottom:10}}>{job.description}</p>
      
      <div id="buttons" style={{marginLeft: "auto", order: 2}}>
        <Button onClick={() => {sendSMS(job.customerId)}} style={{marginRight:20,marginBottom:10}} id="outlined"  color='success'  variant="contained" > Send SMS</Button>
        <Button onClick={() => {sendSMSPaymentLink(job.customerId, job.id)}} style={{marginRight:20,marginBottom:10}} id="outlined"  color='success'  variant="contained" > Send Payment SMS</Button>
        <Button onClick={() => {openGoogleMaps(job.customerId)}} style={{marginRight:20,marginBottom:10}} id="outlined"  color='success'  variant="contained" > Google Maps</Button>
        <Button onClick={() => {editJob(job.id)}} style={{marginRight:20,marginBottom:10}} id="outlined"  color='warning'  variant="contained" > Edit Job</Button>
      </div>

      <DisplayJobOverviewSMSModal customerId={job.customerId} modalIsOpen={modalIsOpen} closeModal={closeModal} />
  </div>        
  );
}
export default DisplayJobOverview;

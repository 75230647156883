import { api } from "./customerbase"
import { defineCancelApiObject } from "./axiosUtils"

export const CustomerAPI = {
    registerUser: function (email, username, password, cancel = false) {
        return api.request({
            url: `/user/register`,
            method: "POST",
            data: { 
                    email: email, 
                    username: username,
                    password: password
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    loginUser: function (username, password, cancel = false) {
        return api.request({
            url: `/user/login`,
            method: "POST",
            data: { 
                    username: username,
                    password: password
                
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    deleteCustomer: function (customerId, cancel = false) {
        return api.request({
            url: `/customer?customerId=` + customerId,
            method: "DELETE",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    saveNewCustomer: function (firstName, lastName, postalCode, addressLineOne, addressLineTwo, phoneNumber, emailAddress, googleContactsJWTToken, cancel = false) {

        return api.request({
            url: `/customer`,
            method: "POST",
            data:{ 
                GoogleJwtKey: googleContactsJWTToken,
                customer: {
                    FirstName: firstName, 
                    LastName: lastName,
                    PhoneNumber: phoneNumber,
                    EmailAddress: emailAddress
                },
                address: {
                    PostCode: postalCode,
                    AddressLineOne: addressLineOne,
                    AddressLineTwo: addressLineTwo
                },
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    editCustomer: function (customer, address, cancel = false) {
        return api.request({
            url: `/customer`,
            method: "PUT",
            data:{ 
                customer: customer,
                address: address
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
    getAllCustomers: function(cancel = false){
        return api.request({
            url: `/customer/all`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
    },
    getById: function(customerId, cancel = false){
        return api.request({
            url: `/customer?id=` + customerId,
            method: "GET",
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
    },
    searchCustomers: function (searchData, cancel = false) {
        return api.request({
            url: `/customer/search`,
            method: "POST",
            data: searchData,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(CustomerAPI)

export default CustomerAPI